<template>
  <div class="system-depute">
    <img
      class="animate__animated"
      v-scroll="'animate__fadeIn'"
      src="@assets/images/SystemDepute8.png"
      alt=""
      width="100%"
    />
    <div class="number1">
      <img src="@assets/images/SystemBuild2.png" alt="" width="100%" />
      <div class="contain">
        <div
          class="Rem0_4 animate__animated"
          v-scroll="'animate__fadeInDown'"
        >
          <p>服务介绍</p>
        </div>
        <p
          class="Rem0_24 animate__animated"
          v-scroll="'animate__fadeIn'"
          style="animation-delay: 1s"
        >
          景翔拥有近三十年管理咨询经验，辅导过上千家企业搭建其管理体系，拥有经验丰富的师资团队，陪伴企业共同发展壮大，企业委托我司维护和不断完善管理制度，提高管理效率，规范化管理流程，使其可以高速发展和扩大规模。您可以将贵司的管理体系委托给我们，专业顾问随时咨询，让您省心、省事、省人!
        </p>
        <div
          class="Rem0_4 animate__animated"
          v-scroll="'animate__fadeInDown'"
        >
          <p>体系落地的服务:</p>
        </div>
        <div class="ul">
          <span
            class="Rem0_25 animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list1"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
        <div class="ul">
          <span
            class="Rem0_25 animate__animated"
            v-scroll="'animate__fadeInRightBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list2"
            :key="index"
            :class="{ dispalyNone: !item }"
          >
            {{ item }}</span
          >
        </div>
      </div>
    </div>
    <div class="number2">
      <div v-scroll="'animate__bounceIn'" class="Rem0_4 animate__animated">
        <p style="color: #4375C8;">企业发展过程中常见的问题</p>
        <p></p>
      </div>
      <div class="contain">
        <img
          class="animate__animated"
          v-scroll="'animate__fadeIn'"
          src="@assets/images/SystemDepute7.png"
          alt=""
        />
        <div class="text">
          <div>
            <p>管理体系</p>
            <p>体系规划</p>
            <p>流程设计</p>
            <p>体系落地</p>
          </div>
          <div>
            <p>
              1、管理体系与战略规划脱钩
              <br />2、顶层设计不合理
              <br />
              3、管理制度越来越多，效率越来越低
              <br />4、缺乏体系指标评估管理体系的有效性
            </p>
            <p>
              1、流程建设滞后，制约企业高速发展 <br />
              2、权责不明，容易扯皮 <br />3、缺乏价值链分析，流程主次不分
              <br />4、业务流程复杂，工作效率低
            </p>
            <p>
              1、制度与信息系统不同步，形成两张皮
              <br />2、纸质文件为主，信息化程度低
              <br />3、数据汇总困难，分析效率低 <br />4、信息孤岛现象明显，信息
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="number3">
      <div class="Rem0_4">
        <p style="color: #4375C8;">为您的体系保驾护航</p>
        <p></p>
      </div>
      <div class="data-item">
        <div
          v-for="(item, index) in dataList2"
          :key="index"
          v-scroll="'animate__fadeInRightBig'"
          class="animate__animated"
        >
          <img :src="item.img" alt="" />
          <p class="Rem0_28">{{ item.p1 }}</p>
          <p class="Rem0_21">{{ item.p2 }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import animateScroll from "../../../modules/AnimateScroll.js";
export default {
  data() {
    return {
      serveList: {
        list1: [
          "经营路线图优化",
          "职能分解维护和更新",
          "目标指标优化",
          "新型价值链优化",
          "信息流维护和更新",
        ],
        list2: [
          "体系树维护和更新",
          "体系树维护和更新",
          "管理手册维护和更新",
          "工作标准的维护和更新",
          "",
        ],
      },
      dataList2: [
        {
          img: require("@assets/images/SystemDepute1.png"),
          p1: "省心、省事、省人",
          p2: "托管式服务，为企业降低体系维护成本",
        },
        {
          img: require("@assets/images/SystemDepute2.png"),
          p1: "专业顾问1V1辅导",
          p2: "每个企业分配专属顾问，问题随时咨询",
        },
        {
          img: require("@assets/images/SystemDepute3.png"),
          p1: "业务变更随时调整",
          p2: "顾问根据企业发展变化，随时提出专业意见与辅导",
        },
        {
          img: require("@assets/images/SystemDepute4.png"),
          p1: "轻松通过外部审核",
          p2: "协助客户审核、第三方审核前提供专业支持和辅导",
        },
        {
          img: require("@assets/images/SystemDepute5.png"),
          p1: "管理体系提升",
          p2: "顾问定期到企业进行管理体系检查，及时优化管理体系",
        },
        {
          img: require("@assets/images/SystemDepute6.png"),
          p1: "人才培养",
          p2: "提供百款管理课程任意挑选",
        },
      ],
    };
  },
  animateScroll,
};
</script>

<style lang="less" scoped>
@import "../../../assets/style/FontSize.css";

.system-depute {
  background: #ffffff;
  @media screen and (min-width: 1200px) and (max-width: 1519px) {
    .number1 {
      .Rem0_4 {
        padding-top: 20px !important;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .number1 {
      .Rem0_4 {
        padding-top: 0px !important;
      }
      .ul span {
        margin: 5px 2px !important;
      }
    }
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    .number1 {
      .Rem0_4 {
        padding-top: 0px !important;
      }
      .ul span {
        margin: 3px 2px !important;
      }
    }
  }

  .number1 {
    position: relative;
    height: 100vh;
    img {
      position: absolute;
    }
    .contain {
      width: 80%;
      margin: 0 10%;
      position: absolute;
      top: 0;
      color: white;
      text-align: start;
      .Rem0_4:nth-of-type(1) {
        display: flex;
        justify-content: center;
      }
      .Rem0_4:nth-of-type(2) {
        margin-top: 40px;
      }
      p:nth-of-type(1) {
        padding-top: 30px;
      }
      .ul {
        width: 100%;
        display: flex;
        justify-content: space-between;

        span {
          flex: 1;
          border: 1px solid;
          text-align: center;
          border: 1px solid #909090;
          border-radius: 5px;
          margin: 0 6.5px;
          margin-top: 20px;
          background: linear-gradient(to right, #555974, #5D616F);
          padding: 0.18rem 0;
          cursor: default;
        }
        .dispalyNone {
          background: transparent;
          border: transparent;
        }
      }
    }
  }

  .number2 {
    position: relative;
    height: 100vh;
    .Rem0_4 {
      text-align: center;
      padding-top: 3%;
      p:nth-of-type(2) {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .contain {
      top: 42%;
      position: relative;
      height: 5.5rem;
      width: 11.5rem;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        position: absolute;
        width: 11.5rem;
      }
      .text {
        > div:nth-of-type(1) {
          p {
            font-size: 0.35rem;
            position: absolute;
            font-weight: bold;
            color: white;
          }
          p:nth-of-type(1) {
            top: 17%;
            left: 16.8%;
          }
          p:nth-of-type(2) {
            top: 42%;
            left: 16.8%;
          }
          p:nth-of-type(3) {
            top: 65%;
            left: 16.8%;
          }
          p:nth-of-type(4) {
            top: 80%;
            left: 16.8%;
          }
        }
        > div:nth-of-type(2) {
          p {
            font-size: 0.2rem;
            position: absolute;
            line-height: 0.35rem;
            color: white;
            font-weight: bold;
          }
          p:nth-of-type(1) {
            top: 2.5%;
            right: 8%;
          }
          p:nth-of-type(2) {
            top: 38%;
            right: 9.5%;
          }
          p:nth-of-type(3) {
            top: 73%;
            right: 8%;
          }
        }
      }
    }
  }
  .number3 {
    height: 100vh;
    text-align: center;
    .Rem0_4 {
      padding-top: 3%;
      p:nth-of-type(2) {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .data-item {
      width: 66%;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      margin: 4% 17% 0 17%;
      > div:nth-of-type(2),
      > div:nth-of-type(5) {
        border-left: 1px solid #e8e7e7;
        border-right: 1px solid #e8e7e7;
      }
      > div:nth-of-type(4),
      > div:nth-of-type(5),
      > div:nth-of-type(6) {
        border-top: 1px solid #e8e7e7;
      }

      > div {
        width: 33%;
        background-color: white;
        padding: 10px 0 30px 0;
        p:nth-of-type(2) {
          margin-top: 5px;
          color: #999999;
          padding: 0 0.8rem;
          line-height: 0.26rem !important;
        }
        img {
          width: 20%;
          padding-top: 30px;
        }
      }
      > div:hover {
        background-color: #5086fa !important;
        p {
          color: white !important;
        }
      }
    }
  }
}
</style>